export enum WeightUnit {
    GRAM = 'GRAM',
    GRAIN = 'GRAIN'
  }
  
  export enum SpineUnit {
    AMO = 'AMO',
    ASTM = 'ASTM'
  }
  
  export class TargetSettings {
    tolleranceWeightEnabled: boolean;
    
    _targetWeightEnabled: boolean;
    set targetWeightEnabled(enabled: boolean) {
      this._targetWeightEnabled = enabled;
      if(enabled) {
        this.tolleranceWeightEnabled = enabled;
      }
    }
    get targetWeightEnabled(): boolean {
      return this._targetWeightEnabled;
    }

    // tolleranceWeight = 2;
  
    tolleranceWeight = 1;
    // private _tolleranceWeight = 1;
    // set tolleranceWeight(weight: number) {
    //   if(this.tolleranceWeightUnit === WeightUnit.GRAM) {
    //     this._tolleranceWeight = Math.abs(weight*10)/10;
    //   } else {
    //     this._tolleranceWeight = Math.abs(weight);
    //   }
    // }
    // get tolleranceWeight(): number {
    //   return this._tolleranceWeight;
    // }
  

    tolleranceWeightUnit: WeightUnit = WeightUnit.GRAM;
    // private _tolleranceWeightUnit = WeightUnit.GRAM;
    // set tolleranceWeightUnit(unit: WeightUnit) {
    //   if(this._tolleranceWeightUnit !== unit)
    //   {
    //     if(this._tolleranceWeightUnit === WeightUnit.GRAM) {
    //       this.tolleranceWeight = Math.round((this.tolleranceWeight * 15.4324));
    //       if(this._targetWeight) {
    //         this.targetWeight = Math.round((this._targetWeight * 15.4324));
    //       }
    //     } else {
    //       this.tolleranceWeight = Math.round((this.tolleranceWeight / 15.4324) * 10) / 10;
    //       if(this._targetWeight) {
    //         this.targetWeight = Math.round((this._targetWeight / 15.4324) * 10) / 10;
    //       }
    //     }
        
    //     this._tolleranceWeightUnit = unit;
    //   }
    // }
    // get tolleranceWeightUnit(): WeightUnit {
    //   return this._tolleranceWeightUnit;
    // }

    targetWeight = 55;
    // _targetWeight = 55;
    // set targetWeight(weight: number) {
    //   this._targetWeight = weight;
    // }
    // get targetWeight(): number {
    //   if(!this.targetWeightEnabled) {
    //     return null;
    //   }
    //   return this._targetWeight;
    // }

    tolleranceSpineEnabled: boolean;
    tolleranceSpine = 2;
    tolleranceSpineUnit = SpineUnit.AMO;
    // private _tolleranceSpineUnit = SpineUnit.AMO;
    // set tolleranceSpineUnit(unit: SpineUnit) {
    //   if(this._tolleranceSpineUnit !== unit)
    //   {
    //     if(this._tolleranceSpineUnit === SpineUnit.AMO) {
    //       if(this._targetSpine) {
    //         this.targetSpine = Math.round((26000 / this._targetSpine * 0.825));
    //       }
    //     } else {
    //       if(this._targetSpine) {
    //         this.targetSpine = Math.round((1/(this._targetSpine / 0.825 / 26000)) * 10) / 10;
    //       }
    //     }
    //     this._tolleranceSpineUnit = unit;
    //   }
    // }
    // get tolleranceSpineUnit(): SpineUnit {
    //   return this._tolleranceSpineUnit;
    // }
  
    _targetSpineEnabled: boolean;
    set targetSpineEnabled(enabled: boolean) {
      this._targetSpineEnabled = enabled;
      if(enabled) {
        this.tolleranceSpineEnabled = enabled;
      }
    }
    get targetSpineEnabled(): boolean {
      return this._targetSpineEnabled;
    }

    targetSpine = 15;
    // _targetSpine = 15;
    // set targetSpine(weight: number) {
    //   this._targetSpine = weight;
    // }
    // get targetSpine(): number {
    //   if(!this.targetSpineEnabled) {
    //     return null;
    //   }
    //   return this._targetSpine;
    // }
    // targetWeightEnabled: boolean;
    // private _targetWeightUnit = WeightUnit.GRAM;
    // set targetWeightUnit(unit: WeightUnit) {
    //   if(this._targetWeightUnit !== unit)
    //   {
    //     if(this._targetWeightUnit === WeightUnit.GRAM) {
    //       this.targetWeight = Math.round((this.targetWeight * 15.4324));
    //     } else {
    //       this.targetWeight = Math.round((this.targetWeight / 15.4324) * 10) / 10;
    //     }
    //     this._targetWeightUnit = unit;
    //   }
    // }
    // get targetWeightUnit(): WeightUnit {
    //   return this._targetWeightUnit;
    // }
  
    // targetSpineEnabled: boolean;
    
    // private _targetSpineUnit = SpineUnit.AMO;
    // set targetSpineUnit(unit: SpineUnit) {
    //   if(this._targetSpineUnit !== unit)
    //   {
    //     if(this._targetSpineUnit === SpineUnit.AMO) {
    //       this.targetSpine = Math.round((26000 / this.targetSpine * 0.825));
    //     } else {
    //       this.targetSpine = Math.round((1/(this.targetSpine / 0.825 / 26000)) * 10) / 10;
    //     }
    //     this._targetSpineUnit = unit;
    //   }
    // }
    // get targetSpineUnit(): SpineUnit {
    //   return this._targetSpineUnit;
    // }
  }