import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from 'rxjs';
import { SpineMeasurement } from "../model/spine.model";




@Injectable({
    providedIn: 'root',
  })
export class SpineMeasurementService {

    public activeMeasurement$ = new BehaviorSubject<SpineMeasurement>(new SpineMeasurement());
    public activeMeasurement = new SpineMeasurement();

    public storedMeasurements = [];
    public storedMeasurements$ = new BehaviorSubject<SpineMeasurement[]>([]);
    public newStoredMeasurement$ = new Subject<SpineMeasurement>();
    public arrowTaken$ = new Subject();

    // public isActiveMeasurementValid(): boolean {
    //   return (this.activeMeasurement.arrowWeight !== undefined && this.activeMeasurement.combinedSpine !== undefined);
    // }
  
    constructor() {}
    
    public newMeasurement(spineMeasurement: SpineMeasurement) {
        this.activeMeasurement = spineMeasurement;
        this.activeMeasurement$.next(this.activeMeasurement);
    }

    public arrowTaken() {
      this.arrowTaken$.next(null);
    }

    public storeMeasurement(spineMeasurement?: SpineMeasurement): boolean {
      const measurement = spineMeasurement || this.activeMeasurement;
      if(measurement.arrowWeight && measurement.combinedSpine && !this.storedMeasurements.includes(measurement)) {
        measurement.storeNumber = this.getNextStoreNumber();
        this.storedMeasurements.push(measurement);
        this.newStoredMeasurement$.next(spineMeasurement);
        this.storedMeasurements$.next(this.storedMeasurements);
        return true;
      }
      return false;
    }

    private getNextStoreNumber() {
      let number = 1;
      this.storedMeasurements.forEach(element => {
        if(element.storeNumber >= number) {
          number = element.storeNumber + 1;
        }
      });
      return number;
    }

    public deleteMeasurement(spineMeasurement: SpineMeasurement) {
      this.storedMeasurements = this.storedMeasurements.filter(obj => {return obj !== spineMeasurement});
      this.storedMeasurements$.next(this.storedMeasurements);
    }

    public deleteAllStoredMeasurements() {
      this.storedMeasurements = [];
      this.storedMeasurements$.next(this.storedMeasurements);
    }

  }