import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

// import { Storage } from '@ionic/storage-angular';

// @Injectable({
//   providedIn: 'root'
// })
// export class StorageService {
//   private _storage: Storage | null = null;

//   constructor(private storage: Storage) {
//   }

//   async init() {
//     // If using, define drivers here: await this.storage.defineDriver(/*...*/);
//     const storage = await this.storage.create();
//     this._storage = storage;
//   }

//   // Create and expose methods that users of this service can
//   // call, for example:
//   public set(key: string, value: any) {
//     this._storage?.set(key, value);
//     // localStorage.setItem(key, value);
//   }
//   public async get(key: string): Promise<string> {
//     return this._storage.get(key);
//     // return localStorage.getItem(key);
//   }
// }


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // private _storage: Storage | null = null;

  // constructor(private storage: Storage) {
  // }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    // const storage = await this.storage.create();
    // this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {

    await Preferences.set({
          key: key,
          value: value,
        });

    // this._storage?.set(key, value);
    // localStorage.setItem(key, value);
  }
  public async get(key: string): Promise<string> {
    const { value } = await Preferences.get({ key: key });
    return value;

    // return this._storage.get(key);
    // return localStorage.getItem(key);
  }
}




// import { Preferences } from '@capacitor/preferences';

// const setName = async () => {
//   await Preferences.set({
//     key: 'name',
//     value: 'Max',
//   });
// };

// const checkName = async () => {
//   const { value } = await Preferences.get({ key: 'name' });

//   console.log(`Hello ${value}!`);
// };

// const removeName = async () => {
//   await Preferences.remove({ key: 'name' });
// };