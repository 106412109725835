import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import { ModeService } from './services/mode.service';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

// import { BLE } from '@awesome-cordova-plugins/ble/ngx';
// import { BleClient } from '@capacitor-community/bluetooth-le';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  prefDark = window.matchMedia('(prefers-color-scheme: dark)');


  constructor( private swUpdate: SwUpdate,
    private alertController: AlertController,
    private modeService: ModeService,
  ) {


// https://forum.ionicframework.com/t/ionic-dark-mode-force-light-dark-and-system-theme/215091
    // this.modeService.checkMode().then((data) => {
    //   if (data === 'auto' || data === 'dark') {
    //     this.modeService.dark = this.prefDark.matches;
    //     this.prefDark.addEventListener('change', e => {
    //       this.modeService.dark = e.matches;
    //     });
    //   }
    // }).catch((err) => console.log(err));


    this.initUpdate();
  }

  isAlertOpen = false;
  // alertButtons = ['Update now', 'Update later'];

  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        console.log('Alert canceled');
      },
    },
    {
      text: 'OK',
      role: 'confirm',
      handler: () => {
        console.log('Alert confirmed');

        this.swUpdate.activateUpdate().then(() => {
          console.warn('downloaded');
          document.location.reload();
      });
      },
    },
  ];

  // alertButtons = [
  //   {
  //     text: 'No',
  //     cssClass: 'alert-button-cancel',
  //   },
  //   {
  //     text: 'Yes',
  //     cssClass: 'alert-button-confirm',
  //   },
  // ];


  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }



  async initUpdate() {
    
    
    try {
      if(this.swUpdate.isEnabled) {
        const updateFound = await this.swUpdate.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } else {
        console.log('PWA update disabled');
        // this.isAlertOpen = true;
      }
    } catch (err) {
      console.error('Failed to check for updates:', err);
    }
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Update available',
      subHeader: 'A Sub Header Is Optional',
      message: 'A message should be a short, complete sentence.',
      buttons: ['Action'],
    });

    await alert.present();
  }
  // constructor(private ble: BLE) {
  //   ble.scan([], 10).subscribe((data) => {
  //     console.log(data);
  //   });
  //  }

  // async scan(): Promise<void> {
  //   try {
  //     await BleClient.initialize({ androidNeverForLocation: true });

  //     const HEART_RATE_SERVICE = '4fafc201-1fb5-459e-8fcc-c5c9c3319143';
  
  //     const device = await BleClient.requestDevice({
  //       services: [HEART_RATE_SERVICE]
  //     });
  
  //     // connect to device, the onDisconnect callback is optional
  //     await BleClient.connect(device.deviceId, (deviceId) => this.onDisconnect(deviceId));
  //     console.log('connected to device', device);



      

  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // onDisconnect(deviceId: string): void {
  //   console.log(`device ${deviceId} disconnected`);
  // }
}
