import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { ConnectionState, ConnectionType, DeviceApi } from "../../model/device.model";
import { SerialWebWrapper } from "./serial-web-wrapper";
// declare let serial: any;

// export enum BleState {
//     CONNECTING,
//     CONNECTED,
//     DISCONNECTING,
//     DISCONNECTED
// }

// export class BleConnectionState{
//     bleState = BleState.DISCONNECTED;
// }

// EXAMPLE: ----------> https://stackblitz.com/edit/rxjs-from-web-serial?file=index.html


// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/w3c-web-serial/w3c-web-serial-tests.ts
// https://www.npmjs.com/package/@types/w3c-web-serial


@Injectable({
    providedIn: 'root',
  })
export class SerialWebConnectionService implements DeviceApi {

  available: boolean = false;
  connectionType: ConnectionType = ConnectionType.SERIAL_WEB;
  deviceName: string;
  dataReceived$ = new Subject<string>();
  connectionState$ = new Subject<ConnectionState>;

  // from: https://github.com/archocron/ngx-serial-example/blob/main/src/app/app.component.ts
  serialWrapper: SerialWebWrapper;
  port:any;

  constructor() {
    this.serialWrapper = new SerialWebWrapper(this.dataHandler);

    if ("serial" in navigator) {
      this.available = true;
      // The Web Serial API is supported.
      console.log('> The Web Serial API is supported' + navigator.serial);
    }
  }

  dataHandler(data: string) {
    console.log("From arduino -> " + data);
    // this.dataReceived$.next(data);
  }

  // private serial = navigator['serial'] as any;
  // private activePort : SerialPort;

  async connect(): Promise<boolean> {
    this.connectionState$.next(ConnectionState.CONNECTING);
    if(!this.port){
      await this.serialWrapper.connect((port:any)=>{
        this.port = port;
      });
    }

    this.connectionState$.next(this.port?ConnectionState.CONNECTED:ConnectionState.DISCONNECTED);
    return this.port != null;

    // this.connectionState$.next(ConnectionState.CONNECTING);

    // if ("serial" in navigator) {
    //   // The Web Serial API is supported.

    //   const s = navigator['serial'] as any;
    // }

    // if (this.serial) {
    //   // The Web Serial API is supported.
    //   console.log('serial');

    //   // navigator.serial.requestPort().then(port => {
    //   //   console.log('port' + port);
    //   // });

    //   this.serial.requestPort().then(port => {
    //     console.log('port' + port);
    //     this.activePort = port;
    //     this.activePort.open({baudRate: 115200});

    //     // https://developer.chrome.com/docs/capabilities/serial?hl=de
    //     const reader = port.readable.getReader();

    //     // Listen to data coming from the serial device.
    //     while (true) {
    //       const { value, done } = reader.read();
    //       if (done) {
    //         // Allow the serial port to be closed later.
    //         reader.releaseLock();
    //         break;
    //       }
    //       // value is a Uint8Array.
    //       console.log('READ: ' + value);
    //     }

        
    //     this.activePort.ondisconnect = (e => {

    //     })
    //   });
    // }

    // this.connectionState$.next(this.activePort?ConnectionState.CONNECTED:ConnectionState.DISCONNECTED);
    // return this.activePort != null;

    // document.querySelector('button').addEventListener('click', async () => {
    //   // Prompt user to select any serial port.
    //   const port = await navigator.serial.requestPort();
    // });
    // Check to see what ports are available when the page loads.
    // document.addEventListener("DOMContentLoaded", async () => {
    //   const ports = await navigator.serial.getPorts();
    //   // Populate the UI with options for the user to select or
    //   // automatically connect to devices.
    // });

    // throw new Error("Method not implemented.");
  }

  async disconnect(): Promise<void> {
    this.connectionState$.next(ConnectionState.DISCONNECTING);
    if(this.port)
        await this.serialWrapper.close((port:any)=>{
          this.port = port;

      });

      this.connectionState$.next(ConnectionState.DISCONNECTED);
  }

  async sendData(string: any): Promise<boolean> {
    if(this.port) {
      await this.serialWrapper.sendData(string);
    } else {
      // this.connectionState$.next(ConnectionState.DISCONNECTED);
    }
    return true;
  }


  // private init() {
  //   serial.requestPermission(function success(), function error());
    // serial.open(opts, function success(), function error());
    // serial.write(data, function success(), function error());
    // serial.read(function success(buffer), function error());
    // serial.registerReadCallback(
    //   function success(data) {
    //     var view = new Uint8Array(data);
    //     console.log(view);
    //   },
    //   function error() {
    //     new Error("Failed to register read callback");
    //   },
    // );
    // And finally close the port:

    // serial.close(function success(), function error())
  // }

}
