import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { ConnectionState, ConnectionType, Device, DeviceApi } from "../model/device.model";
import { SpineMeasurement } from "../model/spine.model";
import { BleConnectionService } from "./device-connection-impl/ble-connection.service";
import { SpineMeasurementService } from "./spine-measurement.service";
import { Platform } from "@ionic/angular";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfigurationService } from "./configuration.service";
import { SpineVisibility, WheightVisibility } from "../model/spine-view.model";
import { AppSettings } from "../model/app-settings.model";
import { SerialWebConnectionService } from "./device-connection-impl/serial-web-connection.service";

// import {
//   serial as polyfill, SerialPort as SerialPortPolyfill,
// } from 'web-serial-polyfill';

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements OnDestroy {
  
  private subscriptions = new Subscription();

  private activeDeviceSubscriptions = new Subscription();

  public connectionState$ = new BehaviorSubject<ConnectionState>(ConnectionState.DISCONNECTED);
  public device$ = new BehaviorSubject<Device>(new Device());

  public connectionPossible$ = new BehaviorSubject<boolean>(true);

  private device = new Device();
  private spineState = new SpineMeasurement();

  // private deviceApis: DeviceApi[] = [];

  private activeDeviceApi: DeviceApi;

  private syncUnitsToDevice: boolean;

  // private bleConnectionState: BleConnectionState;

  constructor(
    private configurationService: ConfigurationService,
    private bleConnectionService: BleConnectionService,
    private serialWebConnectionService: SerialWebConnectionService, 
    private spineMeasurementService: SpineMeasurementService,
    private zone: NgZone,
    // public platform: Platform,
    // private deviceService: DeviceDetectorService
  ) {

    this.subscriptions.add(configurationService.appSettings$.subscribe((settings) => {
      if(!this.syncUnitsToDevice && settings.syncUnitsToDevice) {
        this.syncWeightUnits(configurationService.weightUnits$.getValue());
        this.syncSpineUnits(configurationService.spineUnits$.getValue());
      }
      this.syncUnitsToDevice = settings.syncUnitsToDevice;
    }));

    this.subscriptions.add(configurationService.weightUnits$.subscribe((units) => {
      if (this.syncUnitsToDevice) {
        this.syncWeightUnits(units);
      }
    }));
    this.subscriptions.add(configurationService.spineUnits$.subscribe((units) => {
      if (this.syncUnitsToDevice) {
        this.syncSpineUnits(units);
      }
    }));
      // console.log('Platform: ' + platform.platforms());

      // console.log('hello `Home` component');
      // let deviceInfo = this.deviceService.getDeviceInfo();
      // const isMobile = this.deviceService.isMobile();
      // const isTablet = this.deviceService.isTablet();
      // const isDesktopDevice = this.deviceService.isDesktop();
      // console.log(deviceInfo);
      // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
      // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.

      // https://googlechrome.github.io/samples/web-bluetooth/availability.html
      // if(navigator['bluetooth'] && navigator['bluetooth']['getAvailability']) {
      //   navigator['bluetooth'].getAvailability()
      //     .then(isBluetoothAvailable => {
      //       console.log(`> Bluetooth is ${isBluetoothAvailable ? 'available' : 'unavailable'}`);
      //       this.connectionPossible$.next(true);
      //   }).catch(e => {
      //     console.log('> Bluetooth failed');
      //     this.connectionPossible$.next(false);
      //     });
      // } else {
      //   console.log('> Bluetooth not possible');
      //   this.connectionPossible$.next(false);
      // }


      if ("serial" in navigator) {
        // The Web Serial API is supported.
        console.log('> The Web Serial API is supported' + navigator.serial);

        // Get all serial ports the user has previously granted the website access to.
        // navigator.serial.getPorts().then(ports => {
        //   console.log('> ports ' + ports);
        // });

        // navigator.serial.getPorts().then(ports => {
        //     console.log('> ports ' + ports);
        //   });
        // let p: { (): Promise<SerialPort[]>; (): Promise<SerialPort[]>; } = navigator['serial']['getPorts'];

        // p.prototype.
        // navigator['serial']['getPorts'].then(ports => {
        //   console.log('> ports ' + ports);
        // });
      }
      if(navigator['serial']) {
        console.log('> Serial not null');
      }
      if(navigator['serial'] && navigator['serial']['getAvailability']) {
        // navigator['serial'].getAvailability()
        //   .then(isBluetoothAvailable => {
        //     console.log(`> Serial is ${isBluetoothAvailable ? 'available' : 'unavailable'}`);
        //     // this.connectionPossible$.next(true);
        // }).catch(e => {
        //   console.log('> Serial failed');
        //   // this.connectionPossible$.next(false);
        //   });
      } else {
        console.log('> Serial not possible');
        // this.connectionPossible$.next(false);
      }
      
        
      //   if ('onavailabilitychanged' in navigator.bluetooth) {
      //     navigator.bluetooth.addEventListener('availabilitychanged', function(event) {
      //       console.log(`> Bluetooth is ${event.value ? 'available' : 'unavailable'}`);
      //     });
      //   }
      // }

    // this.subscriptions.add(bleConnectionService.connectionState$.subscribe((state) => {
    //   this.bleConnectionState = state;

    //   if(this.bleConnectionState.bleState === BleState.CONNECTED
    //       && this.device.connected == false) {
    //         this.onConnect();
    //   } else if(this.bleConnectionState.bleState !== BleState.CONNECTED
    //     && this.device.connected == true) {
    //       this.onDisconnect();
    //   }
    
    // })
    // );
  }
  private syncSpineUnits(units: SpineVisibility) {
    switch (units) {
      case SpineVisibility.BOTH:
        this.sendData('cds|0');
        break;
      case SpineVisibility.AMO:
        this.sendData('cds|1');
        break;
      case SpineVisibility.ASTM:
        this.sendData('cds|2');
        break;
    }
  }

  private syncWeightUnits(units: WheightVisibility) {
    switch (units) {
      case WheightVisibility.BOTH:
        this.sendData('cdw|0');
        break;
      case WheightVisibility.GRAM:
        this.sendData('cdw|1');
        break;
      case WheightVisibility.GRAIN:
        this.sendData('cdw|2');
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setActiveDeviceApi(): void {
    if(this.bleConnectionService.available) {
      this.activeDeviceApi = this.bleConnectionService;
      // this.activeDeviceApi = this.serialWebConnectionService;
    }
  }

  public async sendData(s: string): Promise<boolean> {
    return this.activeDeviceApi?.sendData(s) || false;
  }

  // registerDeviceApi(deviceApi: DeviceApi) {
  //   this.deviceApis.push(deviceApi);
  // }

  public async connect(): Promise<boolean> {
    if(this.activeDeviceApi) {
      await this.activeDeviceApi.disconnect();
    }
    this.setActiveDeviceApi();
    this.activeDeviceSubscriptions.unsubscribe();
    this.activeDeviceSubscriptions = new Subscription()
    if(this.activeDeviceApi) {
      this.activeDeviceSubscriptions.add(this.activeDeviceApi.connectionState$.subscribe((state) => {
        console.log('connectionStateChanged', state);
        this.connectionStateChanged(state);
      }));
      this.activeDeviceSubscriptions.add(this.activeDeviceApi.dataReceived$.subscribe((data) => {
        this.dataReceived(data);
      }));
      const connected = await this.activeDeviceApi.connect();
      // if(!connected) {
      //   this.activeDeviceSubscriptions.unsubscribe();
      // }
      return connected;
    }
    this.activeDeviceSubscriptions.unsubscribe()
    this.activeDeviceSubscriptions = new Subscription()
    return false;
  }

  public async disconnect(): Promise<void> {
    if(this.activeDeviceApi) {
      return await this.activeDeviceApi.disconnect();
    }
    this.activeDeviceSubscriptions.unsubscribe();
    this.activeDeviceSubscriptions = new Subscription()
  }

  private connectionStateChanged(connectionState: ConnectionState) {
    this.zone.run(() => {
      switch (connectionState) {
        case ConnectionState.CONNECTING:
          this.onConnecting();
          break;
        case ConnectionState.CONNECTED:
          this.onConnected();
          break;
        case ConnectionState.DISCONNECTING:
          this.onDisconnecting();
          break;
        case ConnectionState.DISCONNECTED:
          this.activeDeviceApi = undefined;
          this.onDisconnected();
          break;
        default:
          break;
      }
      this.connectionState$.next(connectionState);
    });
  }

  // public connect(connectionType?: ConnectionType) {

  // }

  // public disconnect() {

  // }

  private onConnecting(): void {
    this.device = new Device();
    this.device.connectionState = ConnectionState.CONNECTING;
    this.device.connectionType = this.activeDeviceApi.connectionType;
    this.spineState = new SpineMeasurement();
    this.device$.next(this.device);
  }

  private onConnected(): void {
    this.device = new Device();
    this.device.connectionState = ConnectionState.CONNECTED;
    this.device.connectionType = this.activeDeviceApi.connectionType;
    this.spineState = new SpineMeasurement();
    this.device.name = this.activeDeviceApi.deviceName || 'Arrow Analyzer';
    this.device$.next(this.device);
  }

  private onDisconnecting(): void {
    this.device.connectionState = ConnectionState.DISCONNECTING;
    this.device.connectionType = this.activeDeviceApi?.connectionType || ConnectionType.NONE;
    this.spineState = new SpineMeasurement();
    this.device$.next(this.device);
  }

  private onDisconnected(): void {
    this.activeDeviceSubscriptions.unsubscribe();
    this.activeDeviceSubscriptions = new Subscription()
    this.device = new Device();
    this.spineState = new SpineMeasurement();
    this.device$.next(this.device);
  }

  
  private dataReceived(receivedData: string) {
    this.zone.run(() => {
      const values = receivedData.split('|');

      if(receivedData.startsWith('at|')) {
          this.spineState = new SpineMeasurement();
          this.spineMeasurementService.arrowTaken();
          // this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('aw|')) {
          this.spineState.arrowWeight = {gram: parseFloat(values[1]), grain: parseInt(values[2])};
          this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('as1|')) {
          this.spineState.firstSpine = {amo: parseFloat(values[1]), astm: parseInt(values[2])};
          this.spineState.combinedSpine = this.spineState.firstSpine;
          this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('as2|')) {
          this.spineState.secondSpine = {amo: parseFloat(values[1]), astm: parseInt(values[2])};
          this.spineState.straightness = parseFloat(values[3]);
          this.spineState.combinedSpine = {amo: parseFloat(values[4]), astm: parseInt(values[5])};
          this.spineMeasurementService.newMeasurement(this.spineState)
      } else if(receivedData.startsWith('OKgsp|')) {
          this.device.firmwareVersion = values[1];
          this.device.firmwareBuild = parseInt(values[2]);
          this.device.hardwareVersion = values[3];
          this.device.hardwareBuild = parseInt(values[4]);
          this.device.serialNumber = values[5];
          this.device.bootloaderVersion = values[6];
          this.device.bootloaderBuild = parseInt(values[7]);
          this.device.customizeId = values[8];
          this.device$.next(this.device);
      }
    });
  }


  nextArrow = 1;
  simulateArrow() {
    const arrows: [string[]]= [[]];
    arrows.push(['aw|54.7|844|', 'as1|16.2|1943|111.35|', 'as2|16.2|1871|0.007|16.5|1907|15.7|', 'at|'])
    arrows.push(['aw|50.3|804|', 'as1|13.2|1543|111.35|', 'as2|13.2|1571|0.007|13.5|1507|11.7|', 'at|'])
    arrows.push(['aw|74.6|804|', 'as1|113.2|543|111.35|', 'as2|113.2|571|0.007|113.5|507|11.7|', 'at|'])
    arrows.push(['aw|74.6|804|', 'as1|80.2|843|81.35|', 'as2|83.2|871|0.007|83.5|807|16.7|', 'at|'])
    arrows.push(['aw|100.0|1543|', 'as1|80.2|843|81.35|', 'as2|83.2|871|0.007|83.5|807|16.7|', 'at|'])
    // arrows.push(['aw|66.8|950|', 'as1|35.1|956|256.45|', 'at|'])
    arrows[this.nextArrow].forEach((item) => {this.dataReceived(item)});
    this.nextArrow++;
    if(this.nextArrow >= arrows.length) {
      this.nextArrow = 1;
    }
    // this.parseReceivedData('aw|54.7|844|');
    // this.parseReceivedData('as1|16.2|1943|111.35|');
    // this.parseReceivedData('as2|16.2|1871|0.007|16.5|1907|15.7|');
    // this.parseReceivedData('at|');
  }
}