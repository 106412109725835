import { Injectable, OnDestroy } from '@angular/core';
import { AppMode } from '../model/app-settings.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfigurationService } from './configuration.service';
// import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class ModeService implements OnDestroy {

  private subscriptions = new Subscription();

  // public dark$ = new BehaviorSubject<boolean>(false);

  dark: boolean;
  mode = AppMode.AUTO;
  systemPreferDark = window.matchMedia('(prefers-color-scheme: dark)');

  constructor(private configurationService: ConfigurationService) {
    this.subscriptions.add(configurationService.appSettings$.subscribe((appSettings) => {
      this.switchIfNeeded(appSettings.mode);
    })
    );

    this.systemPreferDark.addEventListener('change', event => {
      this.switchIfNeeded(configurationService.appSettings$.value.mode);
    });
  }
 
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private switchIfNeeded(mode: AppMode) {
    this.mode = mode;
    let newDark = (this.mode === AppMode.DARK
      || (this.mode === AppMode.AUTO && this.systemPreferDark.matches)) ? true : false;

    if (newDark != this.dark) {
      this.dark = newDark;
      // this.dark$.next(this.dark);

      document.documentElement.classList.toggle('dark', this.dark);
    }
  }

  // setMode = async (): Promise<void> => {
  //   const storeMode = this.mode;

  //   // await Preferences.set({
  //   //   key: 'mode',
  //   //   value: storeMode
  //   // });

  //   if (this.mode !== 'auto') {
  //     this.dark = (this.mode === 'dark') ? true : false;
  //   } else {
  //     this.dark = this.prefDark.matches;
  //     this.prefDark.addEventListener('change', e => {
  //       this.dark = e.matches;
  //     });
  //   }
  // };

  // checkMode = async () => {
  //   // const { value } = await Preferences.get({ key: 'mode' });
  //   // if (value) {
  //   //   this.mode = value;
  //   // }

  //   return this.mode;
  // };
}