import { Injectable } from "@angular/core";
import { BehaviorSubject, from } from 'rxjs';
import { SpineVisibility, WheightVisibility } from "../model/spine-view.model";
import { TargetSettings } from "../model/target-settings.model";
import { AppSettings } from "../model/app-settings.model";
import { StorageService } from "./storage.service";
import { ExportSettings } from "../model/export-settings.model";


class StorageKeys {
  public static KEY_WEIGHT_UNITS = 'WEIGHT_UNITS';
  public static KEY_SPINE_UNITS = 'SPINE_UNITS';
  public static TKEY_ARGET_SETTINGS = 'TARGET_SETTINGS';
  public static KEY_APP_SETTINGS = 'APP_SETTINGS';
  public static KEY_AUTO_ADD = 'AUTO_ADD';
  public static KEY_TARGET_SETTINGS = 'TARGET_SETTINGS';
  public static KEY_EXPORT_SETTINGS = 'KEY_EXPORT_SETTINGS';
}


@Injectable({
    providedIn: 'root',
  })
export class ConfigurationService {

  // weightUnits = WheightVisibility.BOTH;
  // spineUnits = SpineVisibility.BOTH;

  public weightUnits$ = new BehaviorSubject<WheightVisibility>(WheightVisibility.BOTH);
  public spineUnits$ = new BehaviorSubject<SpineVisibility>(SpineVisibility.BOTH);
  public autoAddMeasurementToList$  = new BehaviorSubject<boolean>(false);
  public targetSettings$ = new BehaviorSubject<TargetSettings>(new TargetSettings());
  public appSettings$ = new BehaviorSubject<AppSettings>(new AppSettings());
  public exportSettings$ = new BehaviorSubject<ExportSettings>(new ExportSettings());
  // public compactSpineLiveView$  = new BehaviorSubject<boolean>(false);

  constructor(private storageService: StorageService) {

    this.storageService.get(StorageKeys.KEY_WEIGHT_UNITS).then(value => {
      try {
        if(value) {
          this.weightUnits$.next(JSON.parse(value));
        }
      } catch (e) {} 
    });

    this.storageService.get(StorageKeys.KEY_SPINE_UNITS).then(value => {
      try {
        if(value) {
          this.spineUnits$.next(JSON.parse(value));
        }
      } catch (e) {}
    });

    this.storageService.get(StorageKeys.KEY_AUTO_ADD).then(value => {
      try {
        if(value) {
          this.autoAddMeasurementToList$.next(JSON.parse(value));
        }
      } catch (e) {}
    });

    this.storageService.get(StorageKeys.KEY_EXPORT_SETTINGS).then(value => {
      try {
        if(value) {
          this.exportSettings$.next(JSON.parse(value));
        }
      } catch (e) {}
    });

    this.storageService.get(StorageKeys.KEY_APP_SETTINGS).then(value => {
      try {
        if(value) {
          this.appSettings$.next(JSON.parse(value));
        }
      } catch (e) {}
    });

    // this.storageService.get(StorageKeys.KEY_TARGET_SETTINGS).then(value => {
    //   try {
    //     console.log(value);
    //     if(value) {
    //       this.targetSettings$.next(JSON.parse(value));
    //     }
    //   } catch (e) {}
    // });
  }
    
  // public setCompactSpineLiveView(compact: boolean) {
  //   this.compactSpineLiveView$.next(compact);
  // }

  public setAppSettings(settings: AppSettings) {
    this.storageService.set(StorageKeys.KEY_APP_SETTINGS, JSON.stringify(settings));
    this.appSettings$.next(settings);
  }

  public setSpineUnit(spineUnit: SpineVisibility) {
    this.storageService.set(StorageKeys.KEY_SPINE_UNITS, JSON.stringify(spineUnit));
    this.spineUnits$.next(spineUnit);
  }

  public setWheightUnit(weightUnit: WheightVisibility) {
    this.storageService.set(StorageKeys.KEY_WEIGHT_UNITS, JSON.stringify(weightUnit));
    this.weightUnits$.next(weightUnit);
  }

  public setAutoAddMeasurementToList(auto: boolean) {
    this.storageService.set(StorageKeys.KEY_AUTO_ADD, JSON.stringify(auto));
    this.autoAddMeasurementToList$.next(auto);
  }

  public setTargetSettings(settings: TargetSettings) {
    this.storageService.set(StorageKeys.KEY_TARGET_SETTINGS, JSON.stringify(settings));
    this.targetSettings$.next(settings);
  }

  public setExportSettings(settings: ExportSettings) {
    this.storageService.set(StorageKeys.KEY_EXPORT_SETTINGS, JSON.stringify(settings));
    this.exportSettings$.next(settings);
  }
}