import { SpineVisibility, WheightVisibility } from "./spine-view.model";

export enum StraighnessDetails {
    NONE = 'NONE',
    COMPACT = 'COMPACT',
    FULL = 'FULL'
}

export enum ExportFormat {
    TEXT = 'TEXT',
    TABLE = 'TABLE',
    HTML = 'HTML',
    CSV = 'CSV',
    PDF = 'PDF'
}

export class ExportSettings{
    weightDetails = WheightVisibility.BOTH;
    spineDetails = SpineVisibility.BOTH;
    straighnessDetails = StraighnessDetails.COMPACT;
    exportFormat = ExportFormat.TEXT;
    weightAndSpineFromView = true;

    // set exportFormat(exportFormat: ExportFormat) {
    //     this._exportFormat = exportFormat;
    // }

    // get exportFormat(): ExportFormat {
    //     return this._exportFormat;
    // }
}