import { BehaviorSubject, Subject } from "rxjs";

export enum ConnectionType {
    NONE = "NONE",
    BLE = "BLE",
    SERIAL_ANDROID = "SERIAL_ANDROID",
    SERIAL_WEB = "SERIAL_WEB"
}

export enum ConnectionState {
    CONNECTING = 'CONNECTING',
    CONNECTED = 'CONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    DISCONNECTED = 'DISCONNECTED'
}

export class Device {
    name?: string;
    firmwareVersion?: string;
    firmwareBuild?: number;
    hardwareVersion?: string;
    hardwareBuild?: number;
    serialNumber?: string;
    bootloaderVersion?: string;
    bootloaderBuild?: number;
    customizeId?: string;
    connectionType = ConnectionType.NONE;
    connectionState = ConnectionState.DISCONNECTED;
}

export interface DeviceApi {
    available: boolean;
    connectionType: ConnectionType;
    deviceName: string;

    dataReceived$: Subject<string>;
    connectionState$: Subject<ConnectionState>;
    
    // isAvailable(): boolean;

    connect(): Promise<boolean>;
    disconnect(): Promise<void>;

    sendData(string): Promise<boolean>;
}
