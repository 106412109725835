import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppHeaderComponentModule } from './components/app-header/app-header.component.module';
// import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './services/storage.service';
import { DatePipe } from '@angular/common';

// https://ionic.io/blog/start-build-deploy-your-first-capacitor-pwa-with-ionic


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, 
    // IonicStorageModule.forRoot(),
    // IonicStorageModule.forRoot({
    //   name: '__svdb',
    //   driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    // }),
    IonicModule.forRoot({innerHTMLTemplatesEnabled: true}), 
    AppRoutingModule, 
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppHeaderComponentModule],
  providers: [
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (storageService: StorageService) => () => storageService.init(),
        deps: [StorageService],
        multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
