
export class AppSettings{
    autoAddMeasurementToList?: boolean;
    theme?: string;
    language?: string;
    syncUnitsToDevice?: boolean;
    mode: AppMode = AppMode.AUTO;
}

export enum AppMode {
    AUTO = 'AUTO',
    DARK = 'DARK',
    LIGHT = 'LIGHT'
  }