import { Injectable } from "@angular/core";
import { BleClient, BleDevice, textToDataView } from "@capacitor-community/bluetooth-le";
import { Subject } from 'rxjs';
import { ConnectionState, ConnectionType, DeviceApi } from "../../model/device.model";

@Injectable({
    providedIn: 'root',
  })
export class BleConnectionService implements DeviceApi {

  readonly ARROW_ANALYZER_SERVICE_ID = '4fafc201-1fb5-459e-8fcc-c5c9c3319143';
  readonly ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID = 'beb5483e-36e1-4688-b7f5-ea07361b26a2';

    // public connectionState$ = new BehaviorSubject<BleConnectionState>(new BleConnectionState());
    // public connectionState = new BleConnectionState();
    private bleConnectionState = ConnectionState.DISCONNECTED;

    // private spineState = new SpineMeasurement();

    available = false;
    deviceName: string;

    dataReceived$ = new Subject<string>();
    connectionState$ = new Subject<ConnectionState>;
    async sendData(string): Promise<boolean> {
      await BleClient.write(
        this.bleDevice.deviceId, 
        this.ARROW_ANALYZER_SERVICE_ID, 
        this.ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID, 
        textToDataView(string));
      return true;
    }
  
    private bleDevice: BleDevice | undefined = undefined;

    connectionType = ConnectionType.BLE;

    constructor() {
      // https://googlechrome.github.io/samples/web-bluetooth/availability.html
      if(navigator['bluetooth'] && navigator['bluetooth']['getAvailability']) {
        navigator['bluetooth'].getAvailability()
          .then(isBluetoothAvailable => {
            console.log(`> Bluetooth is ${isBluetoothAvailable ? 'available' : 'unavailable'}`);
            this.available = true;
        }).catch(e => {
          console.log('> Bluetooth failed');
          this.available = false;
          });
      } else {
        console.log('> Bluetooth not possible');
        this.available = false;
      }
    } 

    public static async isAvailable(): Promise<boolean> {



      // https://googlechrome.github.io/samples/web-bluetooth/availability.html
      if(navigator['bluetooth'] && navigator['bluetooth']['getAvailability']) {
        navigator['bluetooth'].getAvailability()
          .then(isBluetoothAvailable => {
            console.log(`> Bluetooth is ${isBluetoothAvailable ? 'available' : 'unavailable'}`);
            return true;
        }).catch(e => {
          console.log('> Bluetooth failed');
          return false;
          });
      } else {
        console.log('> Bluetooth not possible');
        return false;
      }
    }
  
    private connectionStateChanged(newState: ConnectionState) {
        this.bleConnectionState = newState;
        // this.deviceService.connectionStateChanged(ConnectionType.BLE, newState, deviceName);
        this.connectionState$.next(newState);
    }
  
    async disconnect(): Promise<void> {
      this.connectionStateChanged(ConnectionState.DISCONNECTING);

      console.log('disconnect...');
  
      if(this.bleDevice) {
        await BleClient.stopNotifications(
              this.bleDevice.deviceId,
              this.ARROW_ANALYZER_SERVICE_ID,
              this.ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID);
        await BleClient.disconnect(this.bleDevice.deviceId);
        this.bleDevice = undefined;
      }

      // callback already fired when disconnected
      this.connectionStateChanged(ConnectionState.DISCONNECTED);
    }
  
    async connect(): Promise<boolean> {

      // await BleClient.initialize({androidNeverForLocation: true});

      this.connectionStateChanged(ConnectionState.CONNECTING);
      console.log('scanning...');
  
      try {
        // https://github.com/capacitor-community/bluetooth-le#usage
        await BleClient.initialize({ androidNeverForLocation: true });
  
        
    
        this.bleDevice = await BleClient.requestDevice({
          services: [this.ARROW_ANALYZER_SERVICE_ID]
        });


        // let devices: BleDevice[];
        // devices = await BleClient.getDevices(['Mh40zeVNVe+ZW0+zobifdg==']);
        // console.log('Devices: ', devices);
    
        // connect to device, the onDisconnect callback is optional
        await BleClient.connect(this.bleDevice.deviceId, (deviceId) => this.onDisconnect(deviceId));
        console.log('connected to device', this.bleDevice);
        
  
        await BleClient.startNotifications(
          this.bleDevice.deviceId,
          this.ARROW_ANALYZER_SERVICE_ID,
          this.ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID,
          (value) => {
            var dec = new TextDecoder("utf-8");
            const receivedData = dec.decode(value);
            console.log('received:', dec.decode(value));

            // this.deviceService.dataReceived(ConnectionType.BLE, receivedData);
            this.dataReceived$.next(receivedData);
          }
        );

        const result = await BleClient.read(this.bleDevice.deviceId, this.ARROW_ANALYZER_SERVICE_ID, this.ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID);
        var dec = new TextDecoder("utf-8");
        console.log('read value:', dec.decode(result.buffer));
  
  
  
  
        var enc = new TextEncoder();
        await BleClient.write(this.bleDevice.deviceId, this.ARROW_ANALYZER_SERVICE_ID, this.ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID, textToDataView('gsp'));
        console.log('sending gsp...');
  
        this.connectionStateChanged(ConnectionState.CONNECTED);
        return true;
  
      } catch (error) {
        console.error(error);
        this.disconnect();
      }
      return false;
    }
  
    private onDisconnect(deviceId: string): void {
      console.log(`device ${deviceId} disconnected`);
      if(this.bleDevice) {
        // BleClient.stopNotifications(
        //   this.bleDevice.deviceId,
        //   this.ARROW_ANALYZER_SERVICE_ID,
        //   this.ARROW_ANALYZER_COMMUNICATION_CHARACTERISTIC_ID);
        BleClient.disconnect(this.bleDevice.deviceId); 
        this.bleDevice = undefined;
      }
      this.connectionStateChanged(ConnectionState.DISCONNECTED);
    }


    // parseReceivedData(receivedData: string) {
    //   const values = receivedData.split('|');

    //   if(receivedData.startsWith('at|')) {
    //       this.spineState = new SpineMeasurement();
    //       this.spineMeasurementService.arrowTaken();
    //       // this.spineMeasurementService.newMeasurement(this.spineState)
    //   } else if(receivedData.startsWith('aw|')) {
    //       this.spineState.arrowWeight = {gram: parseFloat(values[1]), grain: parseInt(values[2])};
    //       this.spineMeasurementService.newMeasurement(this.spineState)
    //   } else if(receivedData.startsWith('as1|')) {
    //       this.spineState.firstSpine = {amo: parseFloat(values[1]), astm: parseInt(values[2])};
    //       this.spineState.combinedSpine = this.spineState.firstSpine;
    //       this.spineMeasurementService.newMeasurement(this.spineState)
    //   } else if(receivedData.startsWith('as2|')) {
    //       this.spineState.secondSpine = {amo: parseFloat(values[1]), astm: parseInt(values[2])};
    //       this.spineState.straightness = parseFloat(values[3]);
    //       this.spineState.combinedSpine = {amo: parseFloat(values[4]), astm: parseInt(values[5])};
    //       this.spineMeasurementService.newMeasurement(this.spineState)
    //   }
    // }


    // nextArrow = 1;
    // simulateArrow() {
    //   const arrows: [string[]]= [[]];
    //   arrows.push(['aw|54.7|844|', 'as1|16.2|1943|111.35|', 'as2|16.2|1871|0.007|16.5|1907|15.7|', 'at|'])
    //   arrows.push(['aw|50.7|804|', 'as1|13.2|1543|111.35|', 'as2|13.2|1571|0.007|13.5|1507|11.7|', 'at|'])
    //   // arrows.push(['aw|66.8|950|', 'as1|35.1|956|256.45|', 'at|'])
    //   arrows[this.nextArrow].forEach((item) => {this.parseReceivedData(item)});
    //   this.nextArrow++;
    //   if(this.nextArrow >= arrows.length) {
    //     this.nextArrow = 1;
    //   }
    //   // this.parseReceivedData('aw|54.7|844|');
    //   // this.parseReceivedData('as1|16.2|1943|111.35|');
    //   // this.parseReceivedData('as2|16.2|1871|0.007|16.5|1907|15.7|');
    //   // this.parseReceivedData('at|');
    // }
  }