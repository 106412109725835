
// export enum WheightVisibility {
//     GRAM,
//     GRAIN,
//     BOTH
//   }
  
//   export enum SpineVisibility {
//     AMO,
//     ASTM,
//     BOTH
//   }

  export enum WheightVisibility {
    GRAM = 'GRAM',
    GRAIN = 'GRAIN',
    BOTH = 'BOTH'
  }
  
  export enum SpineVisibility {
    AMO = 'AMO',
    ASTM = 'ASTM',
    BOTH = 'BOTH'
  }
  