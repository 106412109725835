import { Component, OnDestroy, ViewChild } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { IonContent, LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConnectionState } from 'src/app/model/device.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DeviceService } from 'src/app/services/device.service';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnDestroy {

  private subscriptions = new Subscription();

  @ViewChild('mainContent') private content: any;

  connectionState: ConnectionState;
  ConnectionState: any = ConnectionState;

  autoAddToList = false;

  constructor(private deviceService: DeviceService,
    private loadingCtrl: LoadingController, 
    private configurationService: ConfigurationService,
    private swUpdate: SwUpdate) {

    this.subscriptions.add(deviceService.connectionState$.subscribe((state) => {this.connectionState = state})
    );
    this.subscriptions.add(configurationService.autoAddMeasurementToList$.subscribe((autoAdd) => {
      this.autoAddToList = autoAdd;
    }));
    console.log('header');

    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        if (confirm('New version is available, do you want to reload to update?')) {
          document.location.reload();
        }

        // if (promptUser(evt)) {
        //   // Reload the page to update to the latest version.
        //   document.location.reload();
        // }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollToBottom() {
    setTimeout(() => {
      this.content.scrollToBottom(300);
    }, 20);

    let list = document.querySelector("ion-content");
    list && list.scrollToBottom();
  }

  switchAutoAddMeasurementToList(auto: boolean) {
    this.configurationService.setAutoAddMeasurementToList(auto);
  }

  async disconnect(): Promise<void> {
    await this.deviceService.disconnect();
  }

  async connect(): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message: 'Connecting',
    });
    loading.present();

    try {
      await this.deviceService.connect();
    } catch (error) {
      console.error(error);
    }

    loading.dismiss();
  }

  simulateArrow() {
    this.deviceService.simulateArrow();
    // this.toggleDarkMode();
  }

  // toggleDarkMode() {
  //   document.body.classList.toggle('dark');
  // };

}
